<template>
  <div>
    <p class="body-bold mb">
      {{ $t("myPatternResults.patternSearch") }}
    </p>
    <div
      class="box new-results"
      :class="getSelectedResult == null ? 'active' : ''"
      v-on:click="newPattern()"
    >
      {{ $t("myPatternResults.newSearch") }}
    </div>
    <ul class="content" id="style" v-if="!isGettingPatternResults">
      <li
        v-for="backTest in Results"
        :key="backTest.id"
        class="modal-subtitle box"
        :class="getSelectedResult === backTest.id ? 'active' : ''"
      >
        <span class="back-test-box" v-on:click="detailBackTest(backTest.id)">{{
          backTest.name
        }}</span>
        <span class="icons" v-if="loadingId !== backTest.id"
          ><BellOutline
            v-if="!backTest.id_alert"
            v-on:click="setAlert(backTest)"
            :size="20" />
          <BellOffOutline
            :size="20"
            v-else
            v-on:click="
              cancelAlert({ id_alert: backTest.id_alert, id: backTest.id })
            " />
          <close
            class="icons"
            :size="20"
            v-on:click="deleteBackTest(backTest.id)"
        /></span>
        <div class="results-pattern" v-else>
          <b-spinner small />
        </div>
      </li>
    </ul>
    <div v-else class="spinner-holder">
      <b-spinner small />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import BellOffOutline from "vue-material-design-icons/Bell";
import BellOutline from "vue-material-design-icons/BellOutline";
import Close from "vue-material-design-icons/Close";
import MainAlert from "../components/alerts/MainAlert.vue";
import axios from "axios";

export default {
  name: "myPatternResults",
  computed: {
    ...mapGetters("chartStore", {
      Results: "getMyPatternResults",
      getSelectedResult: "getSelectedResult",
      getVisibility: "getVisibility",
      isGettingPatternResults: "isGettingPatternResults",
    }),
  },
  components: { Close, BellOutline, BellOffOutline },
  data() {
    return {
      alertData: [],
      activeItem: null,
      loadingId: null,
    };
  },
  methods: {
    ...mapActions("chartStore", {
      getChartFromBeckTest: "getChartFromBeckTest",
      clearData: "clearData",
      getMyPatternResults: "getMyPatternResults",
    }),

    ...mapMutations("chartStore", {
      setLoading: "setLoading",
      setVisibility: "setVisibility",
      setSimulation: "setSimulation",
      setSelectedResult: "setSelectedResult",
      removePatternResult: "removeMyPatternResult",
      addPatternResultAlert: "addPatternResultAlert",
      removePatternResultAlert: "removePatternResultAlert",
    }),
    deleteBackTest(id) {
      const update = {
        saved: false,
      };
      this.loadingId = id;
      axios
        .patch("/pl2/PatternResults/" + id, update)
        .finally(() => (this.newPattern(), (this.loadingId = null)))
        .then(() => {
          this.removePatternResult(id);
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.deleteBackTestSucces"),
            },
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.deleteBackTestError"),
            },
          });
        });
    },
    detailBackTest(id) {
      this.setLoading(true);
      this.setSimulation(false);
      this.setVisibility(false);
      this.setSelectedResult(id);
      axios
        .get("/pl2/PatternResults/" + id)
        .then((response) => {
          this.getChartFromBeckTest(response.data);
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.detailBackTestError"),
            },
          });
        });
    },
    setAlert(backtest) {
      let alertData = {
        pattern: backtest.pattern,
        ticker: backtest.asset,
        pattern_result: backtest.id,
        app: true,
        email: true,
      };
      this.loadingId = backtest.id;
      axios
        .post("/pl2/Alerts", alertData)
        .then((res) => {
          this.addPatternResultAlert({
            id_alert: res.data.id,
            id: backtest.id,
            pattern: backtest.pattern,
            ticker: backtest.asset,
            time_frame: backtest.time_frame,
          });
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.setAlertSucces"),
            },
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.setAlertError"),
            },
          });
        })
        .finally(() => (this.loadingId = null));
    },
    cancelAlert({ id, id_alert }) {
      this.loadingId = id;
      axios
        .delete("/pl2/Alert/" + id_alert)
        .then(() => {
          this.removePatternResultAlert(id_alert);
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.cancelAlertSucces"),
            },
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("myPatternResults.cancelAlertError"),
            },
          });
        })
        .finally(() => (this.loadingId = null));
    },
    newPattern() {
      if (this.getVisibility) {
        this.setVisibility(false);
      }
      this.setSimulation(false);
      this.setSelectedResult(null);
      this.clearData();
    },
  },
  mounted() {
    this.getMyPatternResults();
  },
};
</script>

<style lang="scss" scoped>
.spinner-holder {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.content {
  height: calc(85vh);
  overflow: auto;
  background: #fff;
  margin-top: toRem(4);
}

#style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(59, 59, 59, 0.15);
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar {
  width: toRem(6);
  background-color: #f5f5f5;
}

#style::-webkit-scrollbar-thumb {
  background-color: $inactive;
}

.box {
  width: toRem(201);
  height: toRem(30);
  border-radius: toRem(7.5);
  font-weight: 400;
  margin: toRem(12) 0;

  &.new-results {
    padding-left: toRem(8);
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
    height: toRem(30) !important;
  }

  &.active {
    background: rgba(54, 135, 255, 0.15);
    font-weight: 500;
    min-height: toRem(30);
    height: auto;
  }
}

ul {
  padding: 0;

  li {
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: toRem(6);

    span {
      cursor: pointer;
      color: $textBlack;
    }
  }
}

.back-test-box {
  padding: toRem(5) 0 0 toRem(8);
  max-width: toRem(140);
}
.results-pattern {
  display: flex;
  align-items: center;
  padding-right: 12px;
}
.mb {
  margin-bottom: toRem(6);
}

.icons {
  padding: toRem(3) toRem(5) 0 0;
}
@media only screen and (max-width: 1023px) {
  #style {
    height: auto;
  }
}
</style>
