<template>
  <sidebars-layout :patternLab="true">
    <template v-slot:left>
      <my-pattern-results />
    </template>

    <div class="init-spinner pattern" v-if="getLoading">
      <b-spinner />
    </div>

    <div v-if="!visibility">
      <hint-description-title
        :title="$t('patternLab.title')"
        cookies="patternLabHint"
        :open="Boolean($cookies.get('patternLabHint'))"
      >
        <div class="image-container">
          <img :src="imageHint" />
        </div>
        <div class="description-hint">
          <p v-html="$t('patternLab.description')"></p>
        </div>
      </hint-description-title>
      <h2 class="pl-h1">{{ $t("patternLab.header") }}</h2>
       <p class="position headline5" v-if="!getLoading">
        {{ $t("patternLab.setParameters") }}
      </p>
      <img
        class="image"
        src="../assets/img/infographics/pattern-man.png"
        alt="Pattern Man"
        srcset=""
      />
    </div>

    <div v-if="visibility">
      <candlestick-chart />
      <div v-if="getSimulation">
        <!--         <timeline-chart /> -->
        <result-table />
        <statistic />
      </div>
    </div>

    <template v-slot:right>
      <control-menu
        @hocPatternChange="setHocPattern"
        @hocAssetChange="setHocAsset"
        @hocTimeFrameChange="setHocTimeFrame"
      />
      <trading-simulator
        v-if="visibility"
        :hocAsset="hocAsset"
        :hocPattern="hocPattern"
        :hocTimeFrame="hocTimeFrame"
      />
    </template>
  </sidebars-layout>
</template>

<script>
import CandlestickChart from "../components/charts/CandlestickChart.vue";
import ControlMenu from "../components/ControlMenuPatternLabForm.vue";
import HintDescriptionTitle from "../components/HintDescriptionTitle.vue";
import MyPatternResults from "../components/MyPatternResults.vue";
import ResultTable from "../components/tables/PatternResultTable.vue";
import SidebarsLayout from "../layouts/SidebarsLayout";
import Statistic from "../components/cards/StatisticPatternLab.vue";
/* import TimelineChart from "../components/charts/TimelineChart.vue"; */
import { TIMEFRAMES } from "../utils/constants";
import TradingSimulator from "../components/forms/TradingSimulatorForm.vue";
import hintImage from "../assets/img/infographics/investment.png";
import { mapGetters } from "vuex";

export default {
  components: {
    CandlestickChart,
    SidebarsLayout,
    ControlMenu,
    HintDescriptionTitle,
    TradingSimulator,
    MyPatternResults,
    /*     TimelineChart, */
    Statistic,
    ResultTable,
    /*     LinesChart, */
  },
  name: "PatternLab",
  data() {
    return {
      imageHint: hintImage,
      hocPattern: null,
      hocAsset: null,
      hocTimeFrame: TIMEFRAMES()[0],
    };
  },
  computed: {
    ...mapGetters("chartStore", {
      visibility: "getVisibility",
      getLoading: "getLoading",
      getSimulation: "getSimulation",
    }),
  },
  methods: {
    setHocPattern(val) {
      this.hocPattern = val;
    },
    setHocAsset(val) {
      this.hocAsset = val;
    },
    setHocTimeFrame(val) {
      this.hocTimeFrame = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.position {
  text-align: center;
  padding-top: toRem(5);
}
</style>
