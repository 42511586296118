<template>
  <div>
    <h1 class="pl-h1 flex align-items-center">
      {{ $t("candlestickChart.header") }} {{ this.pattern.name }}
      {{ $t("candlestickChart.on") }} {{ this.ticker.name }}
      <b-button class="ml-auto" v-b-modal.shareModal variant="primary"
        ><share-icon /> {{ $t("general.share") }}</b-button
      >
    </h1>
    <div id="chart-container">
      <b-row>
        <b-col lg="8" md="12" sm="12" xs="12" class="multiswitch padding15">
          <multi-switch
            v-model="multiSwitchValue"
            :options="options"
            :frame="this.getFrame.id"
          />
        </b-col>
        <b-col lg="4" md="12" sm="12" class="padding15">
          <div class="float-child">
            <div class="bullish">
              <span id="name">{{ $t("general.bullish") }}</span
              ><br /><span id="count">{{ this.getSignalCount.bullish }}</span>
            </div>
          </div>

          <div class="float-child">
            <div class="bearish">
              <span id="name">{{ $t("general.bearish") }}</span
              ><br /><span id="count">{{ this.getSignalCount.bearish }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row cols="12">
        <b-col cols="2">
          <calendar
            :label="$t('candlestickChart.from')"
            :disabled="isDisabled"
            v-model="date.from"
            :maxDate="new Date(date.today - 2 * date.day)"
            data-cy="dateFrom"
          />
        </b-col>
        <b-col cols="2">
          <calendar
            :label="$t('candlestickChart.to')"
            :disabled="isDisabled"
            v-model="date.to"
            :maxDate="new Date(date.today - date.day)"
            data-cy="dateTo"
          />
        </b-col>
      </b-row>
      <b-col>
        <v-chart
          class="candlestick"
          :class="{
            twoCharts:
              getBalanceSeries.length > 0 && getBalanceSeries[0] !== null,
          }"
          :options="chartOptions"
          autoresize
          ref="mainChart"
        />
      </b-col>
      <b-col
        cols="3"
        v-if="getBalanceSeries.length > 0 && getBalanceSeries[0] !== null"
        class="chart-type-switch-holder"
      >
        <select-box
          name="Balance series chart type"
          v-model="balanceSeriesChartType"
          :options="balanceSeriesChartOptions"
          label="name"
          :searchable="false"
          :clearable="false"
        />
      </b-col>
    </div>
    <share-modal />
  </div>
</template>

<script>
import * as constants from "../../utils/constants";

import {
  BALANCE_SERIES_CHART_OPTIONS,
  CANDLESTICK_MULTISWITCH_VALUES,
} from "../../utils/constants";
import { BarChart, CandlestickChart, LineChart } from "echarts/charts";
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  TooltipComponent,
} from "echarts/components";
import { mapActions, mapGetters, mapMutations } from "vuex";

import Calendar from "../inputs/CalendarBox.vue";
import MultiSwitch from "../../components/inputs/MultiSwitch.vue";
import VChart from "vue-echarts";
import SelectBox from "../inputs/SelectBox.vue";
import ShareIcon from "vue-material-design-icons/ShareVariant.vue";
import ShareModal from "../modals/ShareModal.vue";
import { formatDateToCommon } from "../../utils/helper";
import moment from "moment";
import { use } from "echarts/core";

use([
  GridComponent,
  TooltipComponent,
  MarkPointComponent,
  DataZoomComponent,
  CandlestickChart,
  LegendComponent,
  LineChart,
  BarChart,
]);
export default {
  name: "CandleStickChart",
  components: {
    VChart,
    MultiSwitch,
    Calendar,
    SelectBox,
    ShareModal,
    ShareIcon,
  },
  computed: {
    ...mapGetters("chartStore", {
      serialArray: "getSeriesData",
      xAxisArray: "getxAxisData",
      lowPoints: "getMarkPointsLow",
      bigPoints: "getMarkPointsBig",
      getLoading: "getLoading",
      getDate: "getDate",
      pattern: "getPattern",
      ticker: "getTicker",
      getSignalCount: "getSignalCount",
      getFrame: "getFrame",
      getBuyAndHold: "getBuyAndHold",
      getBalanceSeries: "getBalanceSeries",
      getStatusData: "getStatusData",
      selectedTrades: "selectedTrades",
    }),
    isDisabled() {
      return this.multiSwitchValue.id != "c" ? true : false;
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          bottom: 0,
          show: false,
        },
        tooltip: {},
        grid: [
          {
            left: 0,
            width: "absolute",
            right: 38,
            show: true,
            borderWidth: 0,
            backgroundColor: "rgba(255, 255, 255, 1)",
            height: "auto",
          },
          {
            left: 0,
            width: "absolute",
            right: 38,
            show: true,
            borderWidth: 0,
            backgroundColor: "rgba(255, 255, 255, 1)",
            height: "0%",
            top: "61%",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: [],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: false,
              color: "rgba(164, 164, 164, 1)",
              fontFamily: "roboto",
              fontWeight: "500",
              align: "left",
              padding: [0, 5, 0, 5],
              width: 50,
            },
          },
          {
            type: "category",
            data: [],
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              showMinLabel: true,
              showMaxLabel: false,
              color: "rgba(164, 164, 164, 1)",
              fontFamily: "roboto",
              fontWeight: "500",
              align: "left",
              padding: [0, 5, 0, 5],
              width: 50,
            },
            gridIndex: 1,
            show: false,
            position: "bottom",
          },
        ],
        yAxis: [
          {
            scale: true,
            type: "value",
            position: "right",
            offset: 30,
            axisLabel: {
              color: "rgba(164, 164, 164, 1)",
              fontFamily: "roboto",
              fontWeight: "500",
              align: "right",
            },
            splitLine: {
              show: false,
            },
            min: (val) => val.min - val.min * 0.1,
          },
          {
            scale: true,
            type: "value",
            position: "right",
            offset: 30,
            axisLabel: {
              color: "rgba(164, 164, 164, 1)",
              fontFamily: "roboto",
              fontWeight: "500",
              align: "right",
            },
            splitLine: {
              show: false,
            },
            gridIndex: 1,
            min: "dataMin",
            max: "dataMax",
          },
        ],
        dataZoom: [
          {
            show: false,
            realtime: true,
            xAxisIndex: [0, 1],
          },
          {
            type: "inside",
            realtime: true,
            xAxisIndex: [0, 1],
          },
        ],
        series: [
          {
            type: "candlestick",
            data: [],
            itemStyle: {
              color: "rgba(33, 150, 83)",
              borderColor: "rgba(33, 150, 83)",
              color0: "rgb(235,87,87)",
              borderColor0: "rgb(235,87,87)",
            },
            markPoint: {
              symbol: "arrow",
              symbolSize: 8,
              data: [],
              tooltip: {
                formatter: (data) =>
                  `<p>${this.$i18n.t("patternLab.tooltips.date")}: ${
                    data.name
                  }</p><p>${this.$i18n.t(
                    "patternLab.tooltips.direction"
                  )}: ${this.$i18n.t("candlestickChart.bullish")}</p>`,
              },
            },
          },
          {
            type: "candlestick",
            markPoint: {
              symbol: "arrow",
              symbolSize: 8,
              symbolRotate: 180,
              data: [],
              tooltip: {
                formatter: (data) =>
                  `<p>${this.$i18n.t("patternLab.tooltips.date")}: ${
                    data.name
                  }</p><p>${this.$i18n.t(
                    "patternLab.tooltips.direction"
                  )}: ${this.$i18n.t("candlestickChart.bearish")}</p>`,
              },
            },
          },
          {
            name: "Buy and hold",
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            color: "rgb(55, 55, 54)",
          },
          {
            name: "Strategy",
            type: "line",
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: [],
            color: "rgb(190, 21, 34)",
          },
        ],
      },
      date: {
        from: "",
        to: "",
        today: new Date(),
        day: 86400000,
      },
      multiSwitchValue: {
        id: "c",
        name: "Custom",
        from: "",
        to: "",
        maxFrame: null,
      },
      options: CANDLESTICK_MULTISWITCH_VALUES,
      balanceSeriesChartOptions: BALANCE_SERIES_CHART_OPTIONS(),
      balanceSeriesChartType: BALANCE_SERIES_CHART_OPTIONS()[0],
    };
  },
  watch: {
    getLoading: function () {
      this.getChartData();
    },
    "date.from": function (newVal, oldVal) {
      if (oldVal.length > 1) {
        this.setNewDate();
      }
    },
    "date.to": function (newVal, oldVal) {
      if (oldVal.length > 1) {
        this.setNewDate();
      }
    },
    multiSwitchValue: "setSwitch",
    balanceSeriesChartType: "getChartData",
    selectedTrades: "highlightTrade",
  },
  methods: {
    ...mapMutations("chartStore", {
      setDate: "setDate",
      setFrame: "setFrame",
    }),
    ...mapActions("chartStore", {
      getData: "getChartData",
    }),

    getChartData() {
      this.chartOptions.series[0].data = [];
      this.chartOptions.xAxis[0].data = [];
      this.chartOptions.xAxis[1].data = [];
      this.chartOptions.series[1].markPoint.data = [];
      this.chartOptions.series[0].markPoint.data = [];
      this.chartOptions.legend.show = false;

      this.date.from = moment(this.getDate.from).format("YYYY-MM-DD");
      this.date.to = moment(this.getDate.to).format("YYYY-MM-DD");

      this.xAxisArray?.map((data) =>
        this.chartOptions.xAxis[0].data.push(data)
      );
      /*       this.xAxisArray?.map((data) =>
        this.chartOptions.xAxis[1].data.push(data)
      ); */
      if (this.getStatusData) {
        this.chartOptions.xAxis[1].data = Object.keys(
          this.getStatusData.balance_series
        ).map((data) => formatDateToCommon(data));
      }
      this.serialArray?.map((data) =>
        this.chartOptions.series[0].data.push([...data])
      );
      this.lowPoints?.map((data) =>
        this.chartOptions.series[1].markPoint.data.push({ ...data })
      );
      this.bigPoints?.map((data) =>
        this.chartOptions.series[0].markPoint.data.push({ ...data })
      );
      if (
        this.getBalanceSeries.length > 0 &&
        this.getBalanceSeries[0] !== null
      ) {
        this.chartOptions.legend.show = true;
        this.chartOptions.grid[0].height = "46%";
        this.chartOptions.grid[1].height = "32%";
        this.chartOptions.xAxis[1].show = true;
        if (this.balanceSeriesChartType.value === "bars") {
          this.chartOptions.series = [
            this.chartOptions.series[0],
            this.chartOptions.series[1],
            {
              name: "Buy and hold",
              type: "bar",
              data: [],
              barCategoryGap: "0%",
              itemStyle: {
                color: "rgba(235,87,87)",
                borderColor: "rgba(235,87,87)",
              },
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
            {
              name: "Strategy",
              type: "bar",
              data: [],
              itemStyle: {
                color: "rgba(33, 150, 83)",
                borderColor: "rgba(33, 150, 83)",
              },
              barGap: -1,
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
            {
              name: "Buy and hold",
              type: "bar",
              data: [],
              itemStyle: {
                color: "rgba(196, 196, 196)",
                borderColor: "rgba(196, 196, 196)",
              },
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
            {
              name: "Strategy",
              type: "bar",
              data: [],
              itemStyle: {
                color: "rgba(196, 196, 196)",
                borderColor: "rgba(196, 196, 196)",
              },
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
          ];
          Object.keys(this.getStatusData.buy_and_hold).forEach((key, index) => {
            if (this.getBuyAndHold[index] <= this.getBalanceSeries[index]) {
              this.chartOptions.series[4].data.push(this.getBuyAndHold[index]);
              this.chartOptions.series[3].data.push(
                this.getBalanceSeries[index]
              );
              this.chartOptions.series[2].data.push(0);
              this.chartOptions.series[5].data.push(0);
            }
            if (
              this.getBalanceSeries[index] == null ||
              this.getBuyAndHold[index] == null
            ) {
              this.chartOptions.series[4].data.push(this.getBuyAndHold[index]);
              this.chartOptions.series[5].data.push(
                this.getBalanceSeries[index]
              );
              this.chartOptions.series[3].data.push(0);
              this.chartOptions.series[2].data.push(0);
            }
            if (
              this.getBuyAndHold[index] > this.getBalanceSeries[index] &&
              this.getBalanceSeries[index] != null
            ) {
              this.chartOptions.series[2].data.push(this.getBuyAndHold[index]);
              this.chartOptions.series[5].data.push(
                this.getBalanceSeries[index]
              );
              this.chartOptions.series[3].data.push(0);
              this.chartOptions.series[4].data.push(0);
            }
          });
        } else {
          const savedSeries = [
            this.chartOptions.series[0],
            this.chartOptions.series[1],
          ];
          this.chartOptions.series = [];
          if (this.balanceSeriesChartType.value === "exitOnlyLines") {
            const getExits = () => {
              const exitBalances = Object.entries(
                this.getStatusData.output.trades.finish
              ).map((exitDate) => [
                formatDateToCommon(exitDate[1]),

                this.getStatusData.balance_series[
                  moment(exitDate[1]).format("YYYY-MM-DD")
                ],
              ]);
              /*               const exitsOnly = Object.keys(
                this.getStatusData.balance_series
              ).map((date) => {
                console.log(exitBalances);
                console.log(date, "dej");
                const datesExit = exitBalances.find((exit) => exit[0] === date);
                if (datesExit) {
                  return datesExit[1];
                } else {
                  return null;
                }
              }); */

              return exitBalances;
            };
            this.chartOptions.series = [
              ...savedSeries,
              {
                name: "Buy and hold",
                type: "line",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: this.getBuyAndHold,
                color: "rgb(55, 55, 54)",
              },
              {
                name: "Strategy",
                type: "line",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: getExits(),
                color: "rgb(190, 21, 34)",
                markPoint: {},
              },
            ];
          } else {
            this.chartOptions.series = [
              ...savedSeries,
              {
                name: "Buy and hold",
                type: "line",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: this.getBuyAndHold,
                color: "rgb(55, 55, 54)",
              },
              {
                name: "Strategy",
                type: "line",
                xAxisIndex: 1,
                yAxisIndex: 1,
                data: this.getBalanceSeries,
                color: "rgb(190, 21, 34)",
                markPoint: {},
              },
            ];
          }
        }
      }
      this.$refs.mainChart.clear();
    },
    setNewDate() {
      if (this.date.from != this.date.to) {
        this.setDate({
          from: moment(this.date.from),
          to: moment(this.date.to),
        });
        this.getData();
      }
    },
    setSwitch() {
      if (this.multiSwitchValue.id != "c") {
        this.setDate({
          from: moment(this.multiSwitchValue.from),
          to: moment(this.multiSwitchValue.to),
        });
        this.getData();
      }
    },
    highlightTrade(data) {
      const trade = data[0];

      if (!trade) {
        this.chartOptions.series[3].markPoint.data = [];
      } else {
        const typeLong = trade.direction === constants.TICKER_LONG;
        this.chartOptions.series[3].markPoint = {
          data: [
            {
              xAxis: trade.entry_time,
              yAxis:
                this.getStatusData.balance_series[
                  moment(trade.entry_time, constants.DATE_TIME_FORMAT()).format(
                    "YYYY-MM-DD"
                  )
                ],
              itemStyle: {
                color: typeLong ? "#219653" : "#eb5757",
                opacity: this.balanceSeriesChartType.value === "line" ? 1 : 0,
              },
              value: "entry",
              symbol: "arrow",
              symbolRotate: typeLong ? 0 : 180,
            },
            {
              xAxis: trade.exit_time,
              yAxis:
                this.getStatusData.balance_series[
                  moment(trade.exit_time, constants.DATE_TIME_FORMAT()).format(
                    "YYYY-MM-DD"
                  )
                ],
              itemStyle: {
                color: !typeLong ? "#219653" : "#eb5757",
              },
              value: "exit",
              symbol: "arrow",
              symbolRotate: !typeLong ? 0 : 180,
            },
          ],
          symbolSize: 16,
          label: {
            show: false,
            fontSize: 0,
          },
          tooltip: {
            formatter: (data) =>
              `<p>${this.$i18n.t("patternLab.tooltips.type")}: ${this.$i18n.t(
                `patternLab.tooltips.${data.value}`
              )}</p><p>${this.$i18n.t("patternLab.tooltips.date")}: ${
                data.data.xAxis
              }</p><p>${this.$i18n.t("patternLab.tooltips.portfolioValue")}: ${
                data.data.yAxis
              }</p>`,
          },
        };
      }
    },
  },
  mounted() {
    this.getChartData();
  },
};
</script>

<style lang="scss" scoped>
#chart-container {
  background: $lighterBg;
  padding: toRem(14) toRem(16) 0 toRem(16);
  box-sizing: border-box;
  border-radius: $inputRadius;

  .candlestick {
    width: 100%;
    height: toRem(420);
    &.twoCharts {
      height: toRem(700);
    }
  }

  .padding15 {
    padding-bottom: 15px;
  }

  .multiswitch {
    min-width: 455px;
    max-width: 455px;
  }

  .float-child {
    color: $lighterBg;
    text-align: center;
    float: left;
    width: toRem(55);
    height: toRem(55);

    .bullish {
      height: 100%;
      background-color: $greenCandle;
      border-radius: toRem(10) 0 0 toRem(10);
    }

    .bearish {
      height: 100%;
      background-color: $redCandle;
      border-radius: 0 toRem(10) toRem(10) 0;
    }

    span {
      line-height: toRem(24);

      &#count {
        font-size: toRem(20);
        font-weight: 700;
      }
    }
  }
  .chart-type-switch-holder {
    padding-top: toRem(20);
  }
}
</style>
