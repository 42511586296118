var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"cols":"1"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"small-header body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("tradingSimulationForm.initialInvestment")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('tradingSimulationForm.tooltip3')}})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required|min_value:1","name":"initialInvestment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"label":_vm.$t('tradingSimulationForm.amount'),"type":"number","helperText":errors[0],"hasError":failed,"dataCy":"input-amount"},model:{value:(_vm.initial_capital),callback:function ($$v) {_vm.initial_capital=$$v},expression:"initial_capital"}})]}}],null,true)})],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("tradingSimulationForm.investmentRules")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('tradingSimulationForm.tooltip1')}})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"options":_vm.directions,"label":"name","name":_vm.$t('tradingSimulationForm.direction'),"clearable":false,"helperText":errors[0],"hasError":failed,"dataCy":"select-direction"},model:{value:(_vm.direction),callback:function ($$v) {_vm.direction=$$v},expression:"direction"}})]}}],null,true)})],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"small-header body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("tradingSimulationForm.riskPerTrade")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('tradingSimulationForm.riskPerTradeTT')}})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required|maxRisk:@initialInvestment|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"label":_vm.$t('tradingSimulationForm.risk'),"type":"number","helperText":errors[0],"hasError":failed,"dataCy":"input-risk"},model:{value:(_vm.fixed_amount),callback:function ($$v) {_vm.fixed_amount=$$v},expression:"fixed_amount"}})]}}],null,true)})],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"small-header body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("tradingSimulationForm.exitRules")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('tradingSimulationForm.tooltip2')}})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"dataCy":"input-profitTarget","label":_vm.$t('tradingSimulationForm.profitTarget'),"type":"number","helperText":errors[0],"hasError":failed},model:{value:(_vm.profit_target),callback:function ($$v) {_vm.profit_target=$$v},expression:"profit_target"}})]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"dataCy":"select-units","options":_vm.units,"label":"name","name":_vm.$t('tradingSimulationForm.unit'),"clearable":false,"helperText":errors[0],"hasError":failed},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:1|maxSl:@slUnits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"dataCy":"input-stopLoss","label":_vm.$t('tradingSimulationForm.stopLoss'),"type":"number","helperText":errors[0],"hasError":failed},model:{value:(_vm.stop_loss),callback:function ($$v) {_vm.stop_loss=$$v},expression:"stop_loss"}})]}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"slUnits"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"options":_vm.units,"label":"name","name":_vm.$t('tradingSimulationForm.unit'),"clearable":false,"helperText":errors[0],"hasError":failed},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})]}}],null,true)})],1)],1)],1),_c('b-col',[_c('b-button',{staticClass:"button",attrs:{"type":"submit","data-cy":"button-tradingSimulator"}},[_c('PlayCircleOutline',{attrs:{"size":20}}),_vm._v(_vm._s(_vm.$t("tradingSimulationForm.simulation")))],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }