var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"shareModal","hide-ok":"","centered":""},on:{"shown":_vm.handleShown},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"flex align-items-center"},[_c('share-icon',{staticClass:"mr-2"}),_vm._v(_vm._s(_vm.$t("patternLab.shareModal.title"))+" ")],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isInviting},on:{"click":cancel}},[_vm._v(_vm._s(_vm.$t("patternLab.shareModal.close")))])]}}])},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("patternLab.shareModal.description"))+" ")]),_c('validation-observer',{staticClass:"invite-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleInvitation)}}},[_c('validation-provider',{staticClass:"form-field",attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('simple-form-input',{attrs:{"hasError":failed || !_vm.emailExist,"helperText":errors[0],"label":_vm.$t('patternLab.shareModal.inputLabel'),"type":"email"},model:{value:(_vm.inviteMail),callback:function ($$v) {_vm.inviteMail=$$v},expression:"inviteMail"}})]}}],null,true)}),_c('b-button',{attrs:{"type":"submit","disabled":_vm.isInviting}},[(_vm.isInviting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("patternLab.shareModal.buttonText")))],1)],1)]}}])}),(_vm.channelInfo)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t("patternLab.shareModal.accessAllowed")))]),_c('div',{staticClass:"shared-container"},_vm._l((_vm.channelMembers),function(member,i){return _c('div',{key:member.userId,staticClass:"member flex justify-content-between mb-2"},[_c('span',[_c('user-icon',{staticClass:"mr-2",attrs:{"fillColor":_vm.iconsColor}}),_vm._v(" "+_vm._s(member.userId)+" ")],1),(i === 0)?_c('span',{staticClass:"owner"},[_vm._v(" "+_vm._s(_vm.$t("patternLab.shareModal.owner"))+" ")]):_vm._e()])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }