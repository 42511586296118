<template>
  <div>
    <div class="main-title flex">
      <span>{{ title }}</span>
      <slot name="tags" />
      <b-button variant="blank" @click="handleShowHint()">
        <hint-icon :size="24" />
      </b-button>
    </div>
    <b-collapse v-model="showHint" class="hint-container">
      <close-icon
        class="close-icon"
        fillColor="#444"
        :size="16"
        @click="handleShowHint()"
      />
      <slot />
    </b-collapse>
  </div>
</template>

<script>
import closeIcon from "vue-material-design-icons/Close";
import hintIcon from "vue-material-design-icons/HelpCircleOutline";

export default {
  name: "tooltip",
  components: { closeIcon, hintIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    cookies: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showHint: !this.open,
    };
  },
  methods: {
    handleShowHint() {
      this.showHint = !this.showHint;
      if(!this.open){
        this.$cookies.set(this.cookies, true, "1y");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hint-container {
  background: #f3f8ff;
  border-radius: toRem(4);
  padding: toRem(25);
  padding-right: toRem(45);
  margin-bottom: 1rem;
  position: relative;
  font-size: toRem(12);
  .description-hint {
    text-align: justify;
  }
  .image-container {
    width: 25%;
    height: auto;
    max-width: toRem(350);
    margin-bottom: 2rem;
    float: left;
    margin-right: toRem(30);
  }
  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }
}
</style>