<template>
  <b-modal id="shareModal" hide-ok centered @shown="handleShown">
    <template #modal-title
      ><div class="flex align-items-center">
        <share-icon class="mr-2" />{{ $t("patternLab.shareModal.title") }}
      </div></template
    >
    <p>
      {{ $t("patternLab.shareModal.description") }}
    </p>
    <validation-observer v-slot="{ handleSubmit }" class="invite-form">
      <form @submit.prevent="handleSubmit(handleInvitation)">
        <validation-provider
          v-slot="{ failed, errors }"
          rules="email"
          class="form-field"
        >
          <simple-form-input
            v-model="inviteMail"
            :hasError="failed || !emailExist"
            :helperText="errors[0]"
            :label="$t('patternLab.shareModal.inputLabel')"
            type="email"
          />
        </validation-provider>
        <b-button type="submit" :disabled="isInviting">
          <b-spinner small v-if="isInviting" />
          {{ $t("patternLab.shareModal.buttonText") }}</b-button
        >
      </form>
    </validation-observer>
    <div v-if="channelInfo">
      <h2>{{ $t("patternLab.shareModal.accessAllowed") }}</h2>
      <div class="shared-container">
        <div
          class="member flex justify-content-between mb-2"
          v-for="(member, i) in channelMembers"
          v-bind:key="member.userId"
        >
          <span>
            <user-icon :fillColor="iconsColor" class="mr-2" />
            {{ member.userId }}
          </span>
          <span class="owner" v-if="i === 0">
            {{ $t("patternLab.shareModal.owner") }}
          </span>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button variant="primary" @click="cancel" :disabled="isInviting">{{
        $t("patternLab.shareModal.close")
      }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ICONS_FILL_COLOR } from "../../utils/constants";
import MainAlert from "../alerts/MainAlert.vue";
import ShareIcon from "vue-material-design-icons/ShareVariant.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import UserIcon from "vue-material-design-icons/AccountCircle";

export default {
  name: "share-modal",
  components: { ShareIcon, SimpleFormInput, UserIcon },
  data() {
    return {
      inviteMail: "",
      sbUser: null,
      channelInfo: null,
      iconsColor: ICONS_FILL_COLOR,
      isInviting: false,
      emailExist: true,
    };
  },
  computed: {
    ...mapGetters({ user: "getUser" }),
    ...mapGetters("chartStore", { selectedResult: "getSelectedResult" }),
    channelMembers() {
      return this.channelInfo ? [...this.channelInfo.members].reverse() : [];
    },
  },
  methods: {
    ...mapActions("chartStore", ["generatePDFResult"]),
    async handleShown() {
      await this.$sb.groupChannel
        .getChannel(this.selectedResult.toString())
        .then((res) => (this.channelInfo = res))
        .catch(() => (this.channelInfo = null));
    },
    async handleInvitation() {
      this.emailExist = true;
      if (!this.channelInfo) {
        const channel = await this.$sb.groupChannel
          .createChannel({
            name: `Result ${this.selectedResult} shared by ${this.sbUser.userId}`,
            channelUrl: this.selectedResult.toString(),
            customType: "PL",
          })
          .catch(() => this.alertProblem());
        this.channelInfo = channel;
      }
      await this.channelInfo.inviteWithUserIds([this.inviteMail]).catch(() => {
        this.alertProblem();
        this.emailExist = false;
      });

      if (this.emailExist) {
        let shareResult;
        this.isInviting = true;
        await this.generatePDFResult(this.selectedResult)
          .then((res) => {
            const blob = new Blob([res.data], { type: "application/pdf" });
            blob.lastModifiedDate = new Date();
            blob.name = `Result_${this.selectedResult}.pdf`;
            shareResult = blob;
          })
          .catch(() => this.alertProblem())
          .finally(() => {
            this.isInviting = false;
            this.inviteMail = "";
            this.$toast.success({
              component: MainAlert,
              props: {
                message: this.$i18n.t("patternLab.shareModal.alertSuccess"),
              },
            });
          });
        this.channelInfo
          .sendFileMessage({
            file: shareResult,
            fileName: `Result_${this.selectedResult}.pdf`,
            thumbnailSizes: [
              { maxWidth: 100, maxHeight: 100 },
              { maxWidth: 200, maxHeight: 200 },
            ],
            mimeType: "application/pdf",
            requireAuth: false,
          })
          .onFailed(() => {
            this.alertProblem();
          });
      }
    },
    alertProblem() {
      this.isInviting = false;
      this.$toast.error({
        component: MainAlert,
        props: {
          message: this.$i18n.t("patternLab.shareModal.alertError"),
        },
      });
    },
  },
  async mounted() {
    const user = await this.$sb.connect(this.user.email);
    this.sbUser = user;
    this.inviteMail = "";
  },
};
</script>

<style lang="scss" scoped>
.invite-form {
  form {
    display: flex;
    align-items: flex-start;
    .form-field {
      flex: 1;
      margin-right: toRem(10);
    }
    button {
      margin-top: 2px;
    }
  }
}
.shared-container {
  margin-top: 1rem;
}
</style>