<template>
  <div class="input-root">
    <div
      class="input-area"
      :class="{ error: hasError, focused: focused || !!value, disabled }"
    >
      <label>
        {{ label }}
      </label>
      <vc-date-picker
        @input="$emit('input', $event)"
        :value="value"
        :max-date="maxDate"
        :min-date="minDate"
        :locale="$i18n.locale"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            :value="inputValue ? inputValue : value"
            v-on="inputEvents"
            v-bind="$attrs"
            @focus="focused = true"
            @blur="onBlur"
            :disabled="disabled"
          />
        </template>
      </vc-date-picker>
    </div>
    <p :class="{ errorMessage: hasError }" class="inputHelper">
      {{ helperText }}
    </p>
  </div>
</template>

<script>
export default {
  name: "calendarBox",
  props: {
    value: {
      type: String,
      description: "Value of the input",
    },
    label: {
      type: String,
      description: "Label describing the value that should be written in",
    },
    hasError: {
      type: Boolean,
      description: "Whether the input is wrong",
    },
    helperText: {
      type: String,
      description: "Additional information for the input state/value",
      default: "",
    },
    disabled: {
      type: Boolean,
      description: "Whether thi input is disabled",
    },
    maxDate: {
      type: Date,
      description: "The maximum date value the user can select",
    },
    minDate: {
      type: Date,
      description: "The minimum date value the user can select",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    onBlur() {
      (this.focused = false), this.$emit("blur");
    },
    onFocus() {
      this.focused = true;
      this.$emit("focus");
    },
  },
};
</script>

<style lang="scss" scoped>
.input-area {
  min-height: toRem(36);
  position: relative;
  border: 1px solid $separator;
  border-radius: $inputRadius;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  background-color: white;
  &.error {
    background-color: $errorRed;
    border: 1px solid $errorRedBorder;
    label {
      background-color: $errorRed !important;
    }
  }
  input {
    border: none;
    background-color: transparent;
    z-index: 2;
    flex-basis: 100%;
    width: 100%;
  }
  label {
    margin-bottom: 0;
    top: 8px;
    left: 40px;
    position: absolute;
    transition: all 0.5s;
  }
  &.focused {
    border: 1px solid $blueBorders;
    transition: border 0.5s;
    label {
      top: -10px;
      left: 8px;
      background-color: white;
      border-radius: 2px;
      padding: 0 2px;
    }
  }
  &.disabled {
    background-color: $disabled;
    label {
      background-color: $disabled;
    }
  }
}

.inputHelper {
  min-height: toRem(12);
  font-size: toRem(8);
  margin-bottom: 0;
}
</style>