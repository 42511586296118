<template>
  <div class="card" :data-cy="dataCy">
    <p v-if="name" class="body-bold">{{ name }}</p>
    <b-row cols="3" v-for="(data, index) in dataSet" v-bind:key="index">
      <b-col md="7">{{ $t(`${data.name}`) }}</b-col>
      <b-col md="3" class="middle-value body-bold">{{ data.value }}</b-col>
      <b-col md="2" class="tooltip-value" v-if="data.tooltip"
        ><tooltip :title="$t(`${data.tooltip}`)"
      /></b-col>
    </b-row>
    <p class="info-note" v-if="info">{{info}} </p>
  </div>
</template>

<script>
import Tooltip from "../TooltipInfo.vue";

export default {
  name: "card",
  components: {
    Tooltip,
  },
  props: {
    dataSet: {
      type: Array,
      description: "List of data for statistical data",
    },
    name: {
      type: String,
      description: "Card name",
    },
    dataCy: {
      type: String,
      description: "Cypress selector"
    },
    info: {
      type: String,
      description: "Small description"
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  min-width: toRem(280);
  padding: toRem(16);
  background: $lighterBg;
  border: toRem(0.5) solid $blueBorders;
  border-radius: $inputRadius;
  margin-right: toRem(16);

  .middle-value {
    text-align: right;
    padding: 0;
  }

  .tooltip-value {
    text-align: right;
    padding-left: 0;
  }

  .info-note{
    font-size: toRem(11);
    color: #bdbdbd;
    font-style: italic;
    max-width: toRem(280);
    padding-top: 0.15rem;
    margin: 0;
  }
}
</style>