<template>
  <div class="table-container" v-if="this.visibility">
    <b-table
      class="text-center"
      :items="items"
      :fields="fields"
      :perPage="perPage"
      :current-page="currentPage"
      @row-selected="handleRowSelect"
      :selectable="true"
      select-mode="single"
    />
    <table-footer
      :pageSizes="pageSizes"
      :perPage="perPage"
      :totalRows="rows"
      :page="currentPage"
      @pageChange="handlePageChange"
      @perPageChange="handlePerPageChange"
      :export="true"
      :exportName="this.excelName"
      :exportUrl="this.excelUrl"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MainAlert from "../alerts/MainAlert";
import TableFooter from "./tableComponents/TableFooter.vue";

import { formatDateToCommon } from "../../utils/helper";
import i18n from "../../i18n";

export default {
  name: "ResultTable",
  components: {
    TableFooter,
  },
  computed: {
    ...mapGetters("chartStore", {
      getStatusData: "getStatusData",
    }),
    excelUrl() {
      const csvContent = "data:text/csv,";
      let json;

      if (this.items.length > 0) {
        json += [
          Object.keys(this.items[0]).join(";"),
          ...this.items.map((item) => Object.values(item).join(";")),
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
      }
      return csvContent + encodeURI(json.replace("undefinedvalue", "id"));
    },
  },
  data() {
    return {
      fields: [
        {
          key: "direction",
          sortable: true,
        },
        {
          key: "entry_price",
          label: i18n.t("PatternResultTable.entryPrice"),
        },
        {
          key: "exit_price",
          label: i18n.t("PatternResultTable.exitPrice"),
        },
        {
          key: "entry_time",
          label: i18n.t("PatternResultTable.entryTime"),
        },
        {
          key: "exit_time",
          label: i18n.t("PatternResultTable.exitTime"),
        },
        {
          key: "amount",
          label: i18n.t("PatternResultTable.amount"),
        },
        {
          key: "profit",
          label: i18n.t("PatternResultTable.profit"),
        },
      ],
      items: [],
      visibility: false,
      excelName: "",
      rows: null,
      perPage: 5,
      pageSizes: [5, 10, 20],
      currentPage: 1,
    };
  },
  methods: {
    ...mapMutations("chartStore", ["setSelectedTrades"]),
    handlePerPageChange(value) {
      this.$emit("perPageChange", value);
      this.perPage = value;
    },
    handlePageChange(value) {
      this.$emit("pageChange", value);
      this.currentPage = value;
    },
    modifyData() {
      let number = Object.keys(this.getStatusData.output.trades.pnl).length;
      this.excelName = this.getStatusData.name;
      this.rows = number + 1;
      if (number > 0) {
        this.visibility = true;
        for (let i of [...Array(number).keys()]) {
          let amount = 0;
          let direction = null;

          if (
            this.getStatusData.output.trades.longs[i] >
            this.getStatusData.output.trades.shorts[i]
          ) {
            direction = i18n.t("PatternResultTable.long");
            amount = this.getStatusData.output.trades.longs[i];
          } else {
            direction = i18n.t("PatternResultTable.short");
            amount = this.getStatusData.output.trades.shorts[i];
          }
          this.items.push({
            value: i,
            direction: direction,
            entry_price:
              this.getStatusData.output.trades.open_price[i].toFixed(2),
            exit_price:
              this.getStatusData.output.trades.close_price[i].toFixed(2),
            entry_time: formatDateToCommon(
              this.getStatusData.output.trades.start[i]
            ),
            exit_time: formatDateToCommon(
              this.getStatusData.output.trades.finish[i]
            ),
            amount: amount,
            profit: this.getStatusData.output.trades.pnl[i].toFixed(2),
          });
        }
      } else {
        this.visibility = false;
        this.$toast.error({
          component: MainAlert,
          props: {
            message: i18n.t("PatternResultTable.error"),
          },
        });
      }
    },
    handleRowSelect(rows) {
      this.setSelectedTrades(rows);
    },
  },
  mounted() {
    this.modifyData();
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  margin: toRem(24) toRem(10) 0 toRem(31);

  .checkbox {
    margin-left: toRem(10);
    width: 0px;
  }
}
</style>