<template>
  <b-row cols="1">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)" id="control-menu">
        <b-col>
          <b-row>
            <b-col sm="9" class="body-bold">{{
              $t("controlMenu.patternSearch")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('controlMenu.tooltip1')"
            /></b-col>
          </b-row>
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <select-box
              :options="tickers"
              @option:selected="handleSelectTicker"
              label="name"
              :clearable="false"
              :formatOption="true"
              :name="$t('controlMenu.stockName')"
              v-model="ticker"
              :key="tickerKey"
              :loading="isSearchingTicker"
              :searching="isSearchingTicker"
              @focus="getTickers"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="select-assets"
              v-debounce:300ms="getTickers"
              :filter="tickersFilter"
              @loadNextPage="handleLoadNextPage"
              :isLastPage="isLastPage"
              :isAssetsPicker="true"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <select-box
              :options="patterns"
              label="name"
              :clearable="false"
              :name="$t('controlMenu.pattern')"
              v-model="pattern"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="select-patterns"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <b-row>
            <b-col sm="9" class="body-bold">{{
              $t("controlMenu.settings")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('controlMenu.tooltip2')"
            /></b-col>
          </b-row>
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <select-box
              :options="timeFrames"
              label="name"
              :clearable="false"
              :name="$t('controlMenu.data')"
              v-model="frame"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="select-granularity"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <b-button
            type="submit"
            class="button"
            data-cy="button-submit-control-menu"
            ><magnify :size="20" /> {{ $t("controlMenu.search") }}
          </b-button>
        </b-col>
      </form>
    </ValidationObserver>
  </b-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import Magnify from "vue-material-design-icons/Magnify";
import MainAlert from "../components/alerts/MainAlert.vue";
import SelectBox from "../components/inputs/SelectBox.vue";
import { TIMEFRAMES } from "../utils/constants";
import Tooltip from "../components/TooltipInfo.vue";
import axios from "axios";

export default {
  name: "controlMenu-form",
  computed: {
    ...mapGetters("chartStore", {
      patterns: "getListOfPatterns",
      timeFrames: "getTimeFrames",
      getTicker: "getTicker",
      getPattern: "getPattern",
      getSimulation: "getSimulation",
      getVisibility: "getVisibility",
      getFrameName: "getFrameName",
    }),
  },
  components: { SelectBox, Magnify, Tooltip },
  data() {
    return {
      frame: TIMEFRAMES()[0],
      ticker: "",
      pattern: "",
      tickers: [],
      isSearchingTicker: false,
      selectedTicker: null,
      tickerKey: 10,
      assetSearch: "",
      assetsPage: 1,
      maxCount: 0,
      isLastPage: false,
    };
  },
  watch: {
    getTicker: function () {
      if (this.getTicker != null) {
        this.getFormData();
      }
    },
    getVisibility: function (newVal, oldVal) {
      if (oldVal) {
        if (this.getTicker == null || this.getPattern == null) {
          this.cleanFormData();
        }
      }
    },
    pattern: function (val) {
      this.$emit("hocPatternChange", val);
    },
    ticker: function (val) {
      this.$emit("hocAssetChange", val);
    },
    frame: function (val) {
      this.$emit("hocTimeFrameChange", val);
    },
  },
  methods: {
    ...mapActions("chartStore", {
      getData: "getChartData",
      getPatterns: "getListOfPatterns",
      clearData: "clearData",
    }),
    ...mapMutations("chartStore", {
      setFrame: "setFrame",
      setTicker: "setTicker",
      setPattern: "setPattern",
      setDate: "setDate",
      setFrameName: "setFrameName",
    }),
    getFormData() {
      this.ticker = {
        fullName: this.nameTest(this.getTicker.name, this.getTicker.symbol),
        id: this.getTicker.id,
        name: this.getTicker.name,
        symbol: this.getTicker.symbol,
      };
      this.pattern = this.getPattern;
      this.frame = this.getFrameName;
    },
    cleanFormData() {
      (this.ticker = ""), (this.pattern = ""), (this.frame = "");
    },
    onSubmit() {
      this.setTicker(this.ticker);
      this.setFrame(this.frame);
      this.setFrameName(this.frame);
      this.setPattern(this.pattern);
      this.getData();
    },
    nameTest(name, symbol) {
      if (name?.length >= "14") {
        return name.substring(0, 13) + "... (" + symbol + ")";
      } else {
        return name + " (" + symbol + ")";
      }
    },
    getTickers(search) {
      this.isSearchingTicker = true;
      this.assetSearch = search;
      this.assetsPage = 1;
      axios
        .get("/pl2/Ticker", {
          params: {
            search,
          },
        })
        .then((response) => {
          this.tickers = response.data.results.map((result) => {
            return {
              id: result.id,
              symbol: result.symbol,
              name: result.name ? result.name : result.symbol,
              fullName: this.nameTest(result.name, result.symbol),
            };
          });
          this.maxCount = response.data.count;
          if (response.data.next === null) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("controlMenu.tickerError"),
            },
          });
        })
        .finally(() => {
          this.isSearchingTicker = false;
        });
    },
    handleLoadNextPage() {
      this.isSearchingTicker = true;
      axios
        .get("/pl2/Ticker", {
          params: {
            search: this.assetSearch,
            page: this.assetsPage + 1,
          },
        })
        .then((response) => {
          this.tickers = [
            ...this.tickers,
            ...response.data.results.map((result) => {
              return {
                id: result.id,
                symbol: result.symbol,
                name: result.name ? result.name : result.symbol,
                fullName: this.nameTest(result.name, result.symbol),
              };
            }),
          ];
          this.assetsPage = this.assetsPage + 1;
          this.maxCount = response.data.count;
          if (response.data.next === null) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("controlMenu.tickerError"),
            },
          });
        })
        .finally(() => {
          this.isSearchingTicker = false;
        });
    },
    handleSelected() {
      if (this.selectedTicker) {
        this.selectedTicker = null;
        this.tickerKey = this.tickerKey + 1;
      }
    },
    handleSelectTicker(ticker) {
      this.selectedTicker = ticker;
      this.handleSelected();
    },
    tickersFilter(options) {
      return options;
    },
  },
  mounted() {
    this.getPatterns();
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  display: inline-block;
}
</style>
