var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"cols":"1"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"id":"control-menu"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("controlMenu.patternSearch")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('controlMenu.tooltip1')}})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.getTickers),expression:"getTickers",arg:"300ms"}],key:_vm.tickerKey,attrs:{"options":_vm.tickers,"label":"name","clearable":false,"formatOption":true,"name":_vm.$t('controlMenu.stockName'),"loading":_vm.isSearchingTicker,"searching":_vm.isSearchingTicker,"helperText":errors[0],"hasError":failed,"dataCy":"select-assets","filter":_vm.tickersFilter,"isLastPage":_vm.isLastPage,"isAssetsPicker":true},on:{"option:selected":_vm.handleSelectTicker,"focus":_vm.getTickers,"loadNextPage":_vm.handleLoadNextPage},model:{value:(_vm.ticker),callback:function ($$v) {_vm.ticker=$$v},expression:"ticker"}})]}}],null,true)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"options":_vm.patterns,"label":"name","clearable":false,"name":_vm.$t('controlMenu.pattern'),"helperText":errors[0],"hasError":failed,"dataCy":"select-patterns"},model:{value:(_vm.pattern),callback:function ($$v) {_vm.pattern=$$v},expression:"pattern"}})]}}],null,true)})],1),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"body-bold",attrs:{"sm":"9"}},[_vm._v(_vm._s(_vm.$t("controlMenu.settings")))]),_c('b-col',{attrs:{"sm":"3"}},[_c('tooltip',{attrs:{"title":_vm.$t('controlMenu.tooltip2')}})],1)],1),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failed = ref.failed;
var errors = ref.errors;
return [_c('select-box',{attrs:{"options":_vm.timeFrames,"label":"name","clearable":false,"name":_vm.$t('controlMenu.data'),"helperText":errors[0],"hasError":failed,"dataCy":"select-granularity"},model:{value:(_vm.frame),callback:function ($$v) {_vm.frame=$$v},expression:"frame"}})]}}],null,true)})],1),_c('b-col',[_c('b-button',{staticClass:"button",attrs:{"type":"submit","data-cy":"button-submit-control-menu"}},[_c('magnify',{attrs:{"size":20}}),_vm._v(" "+_vm._s(_vm.$t("controlMenu.search"))+" ")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }