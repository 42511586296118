<template>
  <b-row cols="1">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-col>
          <b-row>
            <b-col class="small-header body-bold" sm="9">{{
              $t("tradingSimulationForm.initialInvestment")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('tradingSimulationForm.tooltip3')"
            /></b-col>
          </b-row>
          <ValidationProvider
            rules="required|min_value:1"
            v-slot="{ failed, errors }"
            name="initialInvestment"
          >
            <simple-form-input
              :label="$t('tradingSimulationForm.amount')"
              v-model="initial_capital"
              type="number"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="input-amount"
            ></simple-form-input>
          </ValidationProvider>
        </b-col>

        <b-col>
          <b-row>
            <b-col sm="9" class="body-bold">{{
              $t("tradingSimulationForm.investmentRules")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('tradingSimulationForm.tooltip1')"
            /></b-col>
          </b-row>
          <ValidationProvider rules="required" v-slot="{ failed, errors }">
            <select-box
              :options="directions"
              label="name"
              :name="$t('tradingSimulationForm.direction')"
              :clearable="false"
              v-model="direction"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="select-direction"
            />
          </ValidationProvider>
        </b-col>
        <b-col>
          <b-row>
            <b-col class="small-header body-bold" sm="9">{{
              $t("tradingSimulationForm.riskPerTrade")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('tradingSimulationForm.riskPerTradeTT')"
            /></b-col>
          </b-row>
          <ValidationProvider
            rules="required|maxRisk:@initialInvestment|min_value:1"
            v-slot="{ failed, errors }"
          >
            <simple-form-input
              :label="$t('tradingSimulationForm.risk')"
              v-model="fixed_amount"
              type="number"
              :helperText="errors[0]"
              :hasError="failed"
              dataCy="input-risk"
            ></simple-form-input>
          </ValidationProvider>
        </b-col>

        <b-col>
          <b-row>
            <b-col class="small-header body-bold" sm="9">{{
              $t("tradingSimulationForm.exitRules")
            }}</b-col>
            <b-col sm="3"
              ><tooltip :title="$t('tradingSimulationForm.tooltip2')"
            /></b-col>
          </b-row>
          <b-form-row>
            <b-col sm="6">
              <ValidationProvider
                rules="required|min_value:1"
                v-slot="{ failed, errors }"
              >
                <simple-form-input
                  dataCy="input-profitTarget"
                  :label="$t('tradingSimulationForm.profitTarget')"
                  v-model="profit_target"
                  type="number"
                  :helperText="errors[0]"
                  :hasError="failed"
                ></simple-form-input>
              </ValidationProvider>
            </b-col>
            <b-col sm="6">
              <ValidationProvider rules="required" v-slot="{ failed, errors }">
                <select-box
                  dataCy="select-units"
                  :options="units"
                  label="name"
                  :name="$t('tradingSimulationForm.unit')"
                  :clearable="false"
                  v-model="unit"
                  :helperText="errors[0]"
                  :hasError="failed"
                />
              </ValidationProvider>
            </b-col>
            <b-col sm="6">
              <ValidationProvider
                rules="required|min_value:1|maxSl:@slUnits"
                v-slot="{ failed, errors }"
              >
                <simple-form-input
                  dataCy="input-stopLoss"
                  :label="$t('tradingSimulationForm.stopLoss')"
                  v-model="stop_loss"
                  type="number"
                  :helperText="errors[0]"
                  :hasError="failed"
                ></simple-form-input>
              </ValidationProvider>
            </b-col>
            <b-col sm="6">
              <ValidationProvider
                rules="required"
                v-slot="{ failed, errors }"
                name="slUnits"
              >
                <select-box
                  :options="units"
                  label="name"
                  :name="$t('tradingSimulationForm.unit')"
                  :clearable="false"
                  v-model="unit"
                  :helperText="errors[0]"
                  :hasError="failed"
                />
              </ValidationProvider>
            </b-col>
          </b-form-row>
        </b-col>

        <b-col>
          <b-button
            type="submit"
            class="button"
            data-cy="button-tradingSimulator"
            ><PlayCircleOutline :size="20" />{{
              $t("tradingSimulationForm.simulation")
            }}</b-button
          >
        </b-col>
      </form>
    </ValidationObserver>
  </b-row>
</template>

<script>
import { DIRECTIONS, UNITS } from "../../utils/constants";
import { mapActions, mapGetters, mapMutations } from "vuex";

import MainAlert from "../alerts/MainAlert.vue";
import PlayCircleOutline from "vue-material-design-icons/PlayCircleOutline";
import SelectBox from "../inputs/SelectBox.vue";
import SimpleFormInput from "../inputs/SimpleFormInput.vue";
import Tooltip from "../TooltipInfo.vue";

import axios from "axios";
import moment from "moment";

export default {
  name: "tradingSimulator-form",
  computed: {
    ...mapGetters({ user: "getUser" }),
    ...mapGetters("chartStore", {
      pattern: "getPattern",
      asset: "getTicker",
      frame: "getFrame",
      dates: "getDate",
      statusData: "getStatusData",
      selectedResult: "getSelectedResult",
    }),
  },
  props: ["hocAsset", "hocPattern", "hocTimeFrame"],
  components: { SelectBox, PlayCircleOutline, SimpleFormInput, Tooltip },
  data() {
    return {
      units: UNITS,
      directions: DIRECTIONS(),
      direction: DIRECTIONS()[2],
      unit: UNITS[1],
      profit_target: null,
      stop_loss: null,
      initial_capital: null,
      fixed_amount: null,
    };
  },
  methods: {
    ...mapMutations("chartStore", {
      setLoading: "setLoading",
      setVisibility: "setVisibility",
      setSimulation: "setSimulation",
      setSelectedResult: "setSelectedResult",
    }),
    ...mapActions("chartStore", {
      getChartFromBeckTest: "getChartFromBeckTest",
      getMyPatternResults: "getMyPatternResults",
    }),
    onSubmit() {
      this.setLoading(true);
      const backtestData = {
        user: this.user.pk,
        asset: this.hocAsset.id,
        pattern: this.hocPattern.id,
        time_frame: this.hocTimeFrame.id,
        start_date: moment(this.dates.from).format("YYYY-MM-DD"),
        finish_date: moment(this.dates.to).format("YYYY-MM-DD"),
        direction: this.direction.value,
        profit_take_value: this.profit_target,
        profit_take_unit: this.unit.value,
        stop_loss_value: this.stop_loss,
        stop_loss_unit: this.unit.value,
        entry_type: "MARKET",
        initial_capital: this.initial_capital,
        fixed_amount: this.fixed_amount,
      };
      axios
        .post("/pl2/PatternResults", backtestData)
        .then((response) => {
          this.setSimulation(false);
          this.setVisibility(false);
          this.setSelectedResult(response.data.id);
          const tryIfDone = () => {
            axios
              .get("/pl2/PatternResults/" + response.data.id)
              .then((response) => {
                if (response.data.done) {
                  this.getChartFromBeckTest(response.data);
                  this.getMyPatternResults();
                } else {
                  setTimeout(() => tryIfDone(), 300);
                }
              })
              .catch(() => {
                this.$toast.error({
                  component: MainAlert,
                  props: {
                    message: this.$i18n.t(
                      "tradingSimulationForm.showDetailResultError"
                    ),
                  },
                });
              });
          };
          tryIfDone();
        })
        .then(() => {
          this.$toast.success({
            component: MainAlert,
            props: {
              message: this.$i18n.t("tradingSimulationForm.createResult"),
            },
          });
        })
        .catch(() => {
          this.$toast.error({
            component: MainAlert,
            props: {
              message: this.$i18n.t("tradingSimulationForm.createResultError"),
            },
          });
        });
    },
  },
  mounted() {
    if (this.selectedResult) {
      this.initial_capital = this.statusData.initial_capital;
      this.direction = this.statusData.direction;
      this.fixed_amount = this.statusData.fixed_amount;
      this.profit_target = this.statusData.profit_take_value;
      this.stop_loss = this.statusData.stop_loss_value;
      this.unit = this.units.find(
        (unit) => unit.value === this.statusData.profit_take_unit
      );
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  margin-top: toRem(15);
}

.button {
  width: 100%;
  display: inline-block;
}
</style>
