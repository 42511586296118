<template>
  <div class="container">
    <card
      :name="$t('StatisticPatternLab.performance')"
      :dataSet="performanceData"
      dataCy="card-performance"
    />
    <card
      :name="$t('StatisticPatternLab.summary')"
      :dataSet="summaryData"
      dataCy="card-summary"
    />
  </div>
</template>

<script>
import Card from "../cards/StatisticCard.vue";
import { UNITS } from "../../utils/constants";
import { mapGetters } from "vuex";

export default {
  name: "Statistic",
  components: { Card },
  computed: {
    ...mapGetters("chartStore", {
      getStatusData: "getStatusData",
      getSimulation: "getSimulation",
    }),
  },
  data() {
    return {
      performanceData: [],
      summaryData: [],
      UNITS,
    };
  },
  watch: {
    getSimulation: function () {
      this.getStatusData();
    },
  },
  methods: {
    checkData(value, symbol) {
      return value != null || value != undefined
        ? value + (symbol != null || symbol != undefined ? " " + symbol : "")
        : this.$i18n.t("StatisticPatternLab.noData");
    },
    changeDataSet() {
      this.performanceData.push(
        {
          name: "StatisticPatternLab.cagr",
          value: this.checkData(
            this.getStatusData.output.stats["cagr"]?.toFixed(2),
            "%"
          ),
          tooltip: "StatisticPatternLab.cagrTooltip",
        },
        {
          name: "StatisticPatternLab.shapre",
          value: this.checkData(
            this.getStatusData.output.stats["Sharpe ratio"]?.toFixed(2)
          ),
          tooltip: "StatisticPatternLab.shapreTooltip",
        },
        {
          name: "StatisticPatternLab.capital",
          value: this.checkData(
            Number(
              this.getStatusData["initial_capital"] +
                this.getStatusData.output.stats["Cummulative pnl final"]
            )?.toFixed(2),
            "$"
          ),
          tooltip: "StatisticPatternLab.capitalTooltip",
        },
        {
          name: "StatisticPatternLab.pnl",
          value: this.checkData(
            this.getStatusData.output.stats["Cummulative pnl final"]?.toFixed(
              2
            ),
            "$"
          ),
          tooltip: "StatisticPatternLab.pnlTooltip",
        }
      ),
        this.summaryData.push(
          {
            name: "StatisticPatternLab.trades",
            value: this.checkData(
              Number(
                this.getStatusData.output.stats["Nr of profit trades"] +
                  this.getStatusData.output.stats["Nr of loss trades"]
              )
            ),
            tooltip: "StatisticPatternLab.tradesTooltip",
          },
          {
            name: "StatisticPatternLab.average",
            value: this.checkData(
              this.getStatusData.output.stats[
                "Avg. trade net profit per trade"
              ]?.toFixed(2),
              "$"
            ),
            tooltip: "StatisticPatternLab.averageTooltip",
          },
          {
            name: "StatisticPatternLab.pt",
            value: `${this.checkData(
              this.getStatusData["profit_take_value"]?.toFixed(2)
            )} 
            ${
              this.UNITS.find(
                (unit) => unit.value === this.getStatusData["profit_take_unit"]
              ).name
            }
            `,
            tooltip: "StatisticPatternLab.ptTooltip",
          },
          {
            name: "StatisticPatternLab.sl",
            value: `${this.checkData(
              this.getStatusData["stop_loss_value"]?.toFixed(2)
            )} 
            ${
              this.UNITS.find(
                (unit) => unit.value === this.getStatusData["stop_loss_unit"]
              ).name
            }
            `,
            tooltip: "StatisticPatternLab.slTooltip",
          }
        );
    },
  },
  mounted() {
    this.changeDataSet();
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: toRem(24) 0 0 toRem(16);
}
</style>
